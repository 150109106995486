import { apiEndpoint } from "../environment";

export default class QuestionService {
  constructor(toolKeyname) {
    this.endpoint = `${apiEndpoint}/api/tools/${toolKeyname}`;
  }

  get() {
    return fetch(`${this.endpoint}/questions/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
