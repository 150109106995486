import PropTypes from "prop-types";
import React from "react";

export default function Label(props) {
  return <label>{props.children}</label>;
}

Label.propTypes = {
  children: PropTypes.any,
};
