import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import styles from "./question.module.scss";

const cx = classNames.bind(styles);

export default class Question extends React.Component {
  constructor(props) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  handleChange(answerId) {
    answerId = parseInt(answerId, 10);
    this.setState({ selected: answerId });
    this.props.onChange(answerId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.question.id !== this.props.question.id) {
      if (this.props.question.answerId) {
        this.setState({ selected: this.props.question.answerId });
      }
    }
  }

  componentDidMount() {
    if (this.props.question.answerId) {
      this.setState({ selected: this.props.question.answerId });
    }
  }

  render() {
    this.question = this.props.question;

    return (
      <div className="row g-0">
        <div className="col-12 py-2 mb-3 text-center">
          <h4>{this.props.question.title}</h4>
        </div>

        <div>
          {this.props.question.options.map((option, index) => {
            return (
              <div
                key={`${this.question.id}-${index}`}
                className={`${styles.radioGroup} mb-3`}
              >
                <div
                  className={
                    `${styles.radioButton} ` +
                    cx({
                      selected: option.id === this.state.selected,
                    })
                  }
                  id={option.id}
                  onClick={() => this.handleChange(option.id)}
                ></div>
                <div onClick={() => this.handleChange(option.id)}>
                  {option.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
};
