import React from "react";
import PropTypes from "prop-types";

import Question from "./question/";
import QuestionService from "../../../services/question.service";
import ProgressBar from "../../../components/form/progress-bar/progress-bar";
import translate from "../../../translations";

export default class QuestionForm extends React.Component {
  constructor(props) {
    super();
    this.state = {};
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.qs = new QuestionService(this.props.tool.id);
    if (this.props.answers) {
      this.setState({
        questions: this.props.answers,
        index: this.props.answers.length - 1,
      });
    } else {
      this.setState({
        questions: this.props.tool.questions,
        index: 0,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state.questions);
  }

  onChange(answerId) {
    const questions = this.state.questions;
    const question = questions[this.state.index];
    question.answerId = answerId;
    questions[this.state.index] = question;
    this.setState({ questions });
  }

  hasAnswer() {
    return this.state.questions[this.state.index].answerId !== undefined;
  }

  render() {
    if (this.state.questions) {
      return (
        <>
          <ProgressBar
            total={this.state.questions.length}
            value={this.state.index}
          ></ProgressBar>
          <div className="my-4 text-center">
            <h3 className="text-primary">
              {translate("TextQuestion")} {this.state.index + 1}
            </h3>
          </div>

          <form id="question-form" onSubmit={this.onSubmit}>
            <Question
              onChange={this.onChange}
              question={this.state.questions[this.state.index]}
            ></Question>
            <div className="d-flex justify-content-start mt-4">
              <div id="previous" className="me-3">
                <button
                  onClick={() => this.setState({ index: this.state.index - 1 })}
                  className={"btn btn-outline-primary"}
                  type="button"
                  disabled={this.state.index === 0}
                >
                  {translate("TextPrevious")}
                </button>
              </div>

              <div id="next-submit">
                {this.state.index !== this.state.questions.length - 1 && (
                  <button
                    onClick={() =>
                      this.setState({ index: this.state.index + 1 })
                    }
                    className="btn btn-primary"
                    disabled={
                      this.state.index !== this.state.questions.length - 1 &&
                      !this.hasAnswer()
                    }
                    type="button"
                  >
                    {translate("TextNext")}
                  </button>
                )}

                {this.state.index === this.state.questions.length - 1 && (
                  <button
                    disabled={!this.hasAnswer()}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {translate("TextSubmit")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

QuestionForm.propTypes = {
  tool: PropTypes.object,
  onSubmit: PropTypes.func,
  answers: PropTypes.array,
};
