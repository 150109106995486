import CountryJSON from "../data/countries.json";

import { apiEndpoint } from "../environment";

export default class CountryService {
  constructor() {
    this.countries = CountryJSON.map((x) => x.name);
    this.endpoint = apiEndpoint;
  }

  get() {
    return this.countries;
  }

  whois() {
    return fetch(`${this.endpoint}/api/locator/`, {
      method: "GET",
    });
  }
}
