import translate from "../translations";

export default class ValidatorService {
  apply(validators, value) {
    for (const validator of validators) {
      const validOrMessage = validator(value);
      if (validOrMessage !== true) {
        return validOrMessage;
      }
    }
    return true;
  }

  required(value) {
    if (!value) {
      return translate("ErrorFieldRequired");
    } else {
      return true;
    }
  }

  email(value) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regex.test(value)) {
      return true;
    } else {
      return translate("ErrorFieldEmail");
    }
  }

  sameAs(value, sameAs) {
    if (value !== sameAs) {
      return translate("ErrorFieldSameAs", sameAs);
    } else {
      return true;
    }
  }

  telephone(value) {
    const regex = /^[+]?[0-9()\- ]*$/g;
    if (regex.test(value) && value.length > 6) {
      return true;
    } else {
      return translate("ErrorFieldTelephone");
    }
  }
}
