import React from "react";
import PropTypes from "prop-types";

import s from "./answer.module.scss";

export default class Answer extends React.Component {
  constructor(props) {
    super();
    this.answer = props.answer;
    this.questionNumber = props.questionNumber;
  }

  render() {
    const selectedOption = this.answer.options.find(
      (option) => option.id === this.answer.answerId
    );

    return (
      <div className="row g-0 py-2">
        <div className={`col-1 fw-bold ${s.qNumber}`}>
          Q{this.questionNumber}:
        </div>
        <div className="col-11 row g-0">
          <div className={`col-12 fw-bold ${s.question}`}>
            {this.answer.title}
          </div>
          <div className="col-12">{selectedOption.report_text}</div>
        </div>
      </div>
    );
  }
}

Answer.propTypes = {
  answer: PropTypes.object,
  questionNumber: PropTypes.number,
};
