import React from "react";
import PropTypes from "prop-types";

import s from "./page-title.module.scss";

export default class PageTitle extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  render() {
    return (
      <div className="py-2 mb-4">
        <div className={`text-center ${s.title}`}>{this.props.children}</div>
      </div>
    );
  }
}
PageTitle.propTypes = {
  children: PropTypes.node,
};
