import React from "react";
import PropTypes from "prop-types";
import s from "./loading-spinner.module.scss";

export default function LoadingSpinner(props) {
  return (
    <div
      style={{ fontSize: props.size }}
      className={`${s.loadingSpinner}`}
    ></div>
  );
}

LoadingSpinner.defaultProps = {
  size: "34px",
};

LoadingSpinner.propTypes = {
  size: PropTypes.string,
};
