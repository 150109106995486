import React from "react";
import PropTypes from "prop-types";

import QuestionForm from "./question-form/";

export default class QuestionPage extends React.Component {
  constructor(props) {
    super();
    this.tool = props.tool;
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(questions) {
    this.props.onSubmit(questions);
  }

  render() {
    const answers = this.props.answers;
    return (
      <div className="row g-0">
        <div className="col-12 py-3">
          <QuestionForm
            answers={answers}
            onSubmit={this.onSubmit}
            tool={this.tool}
          ></QuestionForm>
        </div>
      </div>
    );
  }
}

QuestionPage.propTypes = {
  onSubmit: PropTypes.func,
  tool: PropTypes.object,
  answers: PropTypes.array,
};
