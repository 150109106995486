import { apiEndpoint } from "../environment";

export default class QuestionService {
  constructor(toolId) {
    this.endpoint = `${apiEndpoint}/api/tools/${toolId}`;
  }

  submit(data) {
    return fetch(`${this.endpoint}/responses/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  update(responseId, data) {
    return fetch(`${this.endpoint}/responses/${responseId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
}
