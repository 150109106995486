import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./progress-bar.module.scss";

const cx = classNames.bind(styles);

export default class ProgressBar extends React.Component {
  page_views_sent = [];

  constructor(props) {
    super();
  }

  componentDidUpdate() {
    const q = this.props.value;

    if(window.parent && !(q in this.page_views_sent)) {
      window.parent.postMessage({
        ga4_event: "page_view",
        eventData: {
          page_location: '#q' + (q + 1),
        },
      }, "*");
      this.page_views_sent.push(q);
    }
  }

  render() {
    // const percentage = Math.round((this.props.value / this.props.total) * 100)
    const sections = [];
    for (let x = 0; x < this.props.total; x++) {
      const className = cx({
        active: x === this.props.value,
        completed: x < this.props.value,
      });
      let textContent = x + 1;

      if (x < this.props.value) {
        textContent = (
          <span className={` material-icons ${styles.icon}`}>done</span>
        );
      }

      const wrapperClass = cx({
        "d-none d-md-block":
          (x !== this.props.value) & (x !== this.props.total - 1),
      });
      sections.push(
        <div
          key={x}
          className={
            `text-center w-100 ${wrapperClass} ` + styles["item-wrapper"]
          }
        >
          <div className={`${styles.item} ${className}`}>
            <div className="h-100 d-flex align-items-center justify-content-center">
              {textContent}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`${styles["progress-bar-wrapper"]}`}>
        <div className={`d-flex ${styles["progress-bar"]}`}>{sections}</div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  total: PropTypes.number,
  value: PropTypes.number,
};
