import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";

export default function Input(props) {
  return (
    <>
      <Field
        className="form-control form-control-lg"
        name={props.name}
        validate={(value) => {
          for (const validator of props.validators) {
            const validationResult = validator(value);
            if (validationResult !== true) {
              return validationResult;
            }
          }
          return null;
        }}
      />
      <ErrorMessage className="text-danger" name={props.name} component="div" />
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  validators: PropTypes.array,
};
