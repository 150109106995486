import PropTypes from "prop-types";
import React from "react";

export default function FormGroup(props) {
  return <div className="mb-3">{props.children}</div>;
}

FormGroup.propTypes = {
  children: PropTypes.array,
};
