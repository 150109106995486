import { apiEndpoint } from "../environment";

export default class ToolService {
  constructor() {
    this.endpoint = `${apiEndpoint}/api/tools`;
  }

  get() {
    return fetch(`${this.endpoint}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDetail(toolId) {
    return fetch(`${this.endpoint}/${toolId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
